<template>
  <div>
    <table border="0" cellspacing="0">
      <thead class="head">
        <th class="num">帐号</th>
        <th class="phone">联系方式</th>
        <th class="limits">权限</th>
        <th class="weixin">微信</th>
        <th class="addtime">添加时间</th>
        <th class="operate">操作</th>
      </thead>
      <tbody>
        <tr
          :class="
            (index % 2 == 0 && !changecolor) || (index % 2 !== 0 && changecolor)
              ? 'bg_color'
              : 'bg_color2'
          "
          v-for="(data, index) in list"
          :key="index"
        >
          <td class="td-num">
            <i @click="detail(data.uid)" class="xiangqing">{{ data.username }}</i>
          </td>
          <td class="td-phone">{{ data.name }}/{{ data.tel }}<button
                    @click="call(data.tel,'')"
                    class="call"
                    v-show="getEmic != '' && data.tel != ''"
                  ></button></td>
          <td
            @mouseenter="mouseenterFun(index)"
            @mouseleave="mouseleaveFun"
            class="td-limits"
          >
            {{ data.role }}
          </td>
          <td :class="data.bind == '未绑定' ? 'bang' : 'td-weixin'">
            {{ data.bind }}

            <div
              class="roleMain"
              v-show="
                data.role.length > roleLength ? index == enterIndex : false
              "
            >
              {{ data.role }}
            </div>
          </td>
          <td class="td-addtime">{{ data.create_time }}</td>
          <td class="td-operate">
            <!-- <i @click="detail(data.uid)" class="check">查看</i>
            <i class="tiao"></i> -->
            <i class="know" v-if="data.status == 1 && getlook"
              >已启用</i
            >
            <i class="know1" v-if="data.status == 0  && getlook"
              >已禁用</i
            >
            
            <i @click="disable(data.uid)" class="know" v-if="data.status == 1 && !getlook"
              >已启用</i
            >
            <i @click="enable(data.uid)" class="know1" v-if="data.status == 0  && !getlook"
              >已禁用</i
            >
            <!--<i class="tiao"></i>
            <i @click="dail(data.uid)" class="log">日志</i>-->
          </td>
        </tr>
      </tbody>
    </table>

    <!-- 分页器 -->
    <div class="jinXing-sorter">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="datas.perpage"
        @prev-click="prev_view"
        @next-click="next_view"
        @size-change="size_view"
        @current-change="current_view"
      >
      </el-pagination>
    </div>

    <enable :isable="able" :enableuid="uid" @enablesx="sx"></enable>
    <detail
      :istail="tail"
      :userinfo="userinfo"
      :listData="rolelist"
      :checkedLists="checkedlist"
      @detailsx="sx1"
      isreadonly="true"
    ></detail>
    <!-- <detail :istail="tail" :uid="uid"></detail> -->
    <daily :isail="ail" :dailyuid="duid"></daily>
  </div>
</template>

<script>
import * as api from "../api/user";
import * as ola from "../api/olawebsock.js"; 
import Daily from "./daily.vue";
import Detail from "./detail.vue";
import enable from "./enable.vue";
import { ElMessageBox } from "element-plus";
export default {
  props: ["issx", "skeyword","isState"],
  components: { enable, Detail, Daily },
  data() {
    return {
      able: 0,
      tail: 0,
      isshow: false,
      ail: 0,
      list: [],
      rolelist: [],
      checkedlist: [],
      userinfo: {},
      uid: "",
      pagesize: 1,
      total: 100,
      datas: {
        page: 1,
        perpage: 7,
        keyword: "",
        status:2,
      },
      enterIndex: -1,
      roleLength: 31,
      duid: "",
      emicreg:'',
    };
  },

  async created() {
    this.res_userlist(this.datas);
  },

  methods: {
    async res_userlist(data) {
      let res_userlist = await api.userList(data);
      console.log("人员列表", res_userlist);
      this.list = res_userlist.data;
      this.total = parseInt(res_userlist.total);
    },
    sx() {
      this.res_userlist(this.datas);
    },
    sx1() {
      this.res_userlist(this.datas);
    },

    mouseenterFun(index) {
      this.enterIndex = index;
    },
    mouseleaveFun() {
      this.enterIndex = -1;
    },
    async enable(id) {
      this.uid = id;
      this.able++;
      if (this.able == 3) {
        this.able = 1;
      }
    },
    async detail(id) {
      this.tail++;
      if (this.tail == 3) {
        this.tail = 1;
      }
      // this.uid = uid
      let res_userdetail = await api.userDetail(id);
      console.log("userDeatil:", res_userdetail);
      this.userinfo = res_userdetail.data;
      this.rolelist = res_userdetail.data.rolelist;
      this.checkedlist = res_userdetail.data.nowrole;
    },
    dail(uid) {
      this.ail++;
      if (this.ail == 3) {
        this.ail = 1;
      }
      this.duid = uid;
    },

    getInitial() {
      for (var i = 0; i < this.count; i++) {
        this.list.push(this.list[i]);
      }
    },

    async disable(id) {
       ElMessageBox.confirm("是否确定禁用该账号", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
            let userdisable = await api.userDodisable(id);
            if (userdisable.code == 1) {
              // alert('已禁用');
              this.$message.warning("已禁用");
              this.sx();
            } else {
              // alert(userdisable.message);
              this.$message.warning(userdisable.message);
            }
        })
        .catch(() => {
          this.$message.info("取消操作");
        });
   
    },

    // 分页器
    prev_view() {
      console.log("---人员上一页", this.datas.page);
    },
    // 分页器
    next_view() {
      console.log("---人员下一页", this.datas.page);
    },
    // 点击页数时触发
    current_view(e) {
      this.datas.page = e;
      this.res_userlist(this.datas);
    },
    call(tel,str) {
      let ue =  this.$store.state.emic;
      let mode = this.$store.state.emicmode;

      console.log("拨打电话", tel);
      ue.call.callout({
        customerNumber:tel.toString(),
        loginType:mode,
        extras:str
      });
      },
  },
  computed: {
    getEmic() {
      return this.$store.state.emic;
    },
    getismain() {
      return this.$store.state.ismain;
    },
    getlook()
    {
      return this.$store.state.lookonly;
    }
  },
  watch: {
    issx(newval) {
      console.log("来了", newval);
      this.res_userlist(this.datas);
    },
    skeyword(newval) {
      this.datas.keyword = newval;
      this.res_userlist(this.datas);
    },
    isState(newval) {
      this.datas.status = newval
      this.res_userlist(this.datas);
    }
  },
  beforeMount() {
    // 在页面挂载前就发起请求
    this.getInitial();
  },
};
</script>

<style lang="less" scoped>
.roleMain {
  width: 420px;
  height: 70px;
  background-color: #fff;
  position: absolute;
  top: -92px;
  left: -234px;
  box-shadow: 0px 4px 5px;
  padding: 10px;
  // display: none;
}

table {
  width: 100%;
  padding-right: 30px;
  // margin-left: 30px;
}
.head {
  text-align: left;
  // width: 1610px;
  // height: 50px;
  box-sizing: border-box;
  background-color: #f5f5f5;
}
.num,
.phone,
.limits,
.weixin,
.addtime,
.operate {
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 0px;
  color: #2a3346;
  opacity: 1;
  padding-top: 15px;
  padding-bottom: 15px;
}
.num,
.td-num {
  padding-left: 30px;
}
th {
  width: 200px;
  // padding-left: 30px;
}
tr {
  height: 70px !important;
  padding-left: 30px;
}
.td-num,
.td-phone,
.td-limits,
.td-weixin,
.td-addtime {
  // height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 32px;
  color: #727e96;
  opacity: 1;
}
.td-num {
  width: 90px;
}
.td-phone {
  width: 138px;
}
.td-limits {
  width: 202px;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
  // line-height: 40px;
}
.td-weixin {
  width: 42px;
  position: relative;
}
.td-addtime {
  width: 138px;
}

tr:hover {
  background-color: #f9fafe !important;
}
.bg_color {
  background-color: #ffffff !important;
}

.bg_color2 {
  background-color: #fafafa !important;
}

.tiao {
  display: inline-block;
  width: 0px;
  height: 14px;
  margin-right: 10px;
  margin-left: 10px;
  box-sizing: border-box;
  border: 1px solid #dadfe6;
  opacity: 1;
}
i {
  font-style: normal;
}
.check,
.know,
.limit,
.log {
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 32px;
  color: #2c68ff;
  opacity: 1;
  cursor: pointer;
}
.check,
.limit,
.log {
  width: 32px;
  cursor: pointer;
}
.know {
  width: 48px;
  cursor: pointer;
}
.know1 {
  color: #ff505a;
  cursor: pointer;
  font-size: 16px;
}

.jinXing-sorter {
  position: absolute;
  bottom: 30px;
  right: 30px;
  padding: 30px;
}

.call {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: url("../assets/img/index/call.png");
  background-size: contain;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  vertical-align: middle;
  margin-left: 5px;
}
.carcall {
  display: flex;
  align-items: center;
}
.bang{
  position: relative;
  font-size: 14px;
  color: #727e96;
}
i,.xiangqing{
  font-style: normal;
  color: #2c68ff;
}
.xiangqing{
  cursor: pointer;
}
</style>
