<template>
  <div>
    <div class="box" v-show="ails">
      <img
        class="cha"
        @click="close"
        src="../assets/img/ding/icon-close.png"
        alt=""
      />
      <div class="box-main">
        <div class="main-nav">
          <h1>人员管理日志</h1>
          <img class="shua" src="../assets/img/ding/shua.png" alt="" />
          <i class="xin" @click="getlog">刷新</i>
        </div>

        <table border="0" cellspacing="0">
          <thead class="hand">
            <th class="name">姓名/帐号</th>
            <th class="limits">操作时间</th>
            <th class="weixin">操作</th>
            <th class="marketing">IP</th>
          </thead>
          <tbody>
            <tr
            :class="(index%2==0&&!changecolor||index%2!==0&&changecolor)?'bg_color':'bg_color2'"
            v-for="(item,index) in jounList" :key="index"
            >
              <td class="td-name">{{item.name}}</td>
              <td class="td-limits">{{item.limits}}</td>
              <td class="td-weixin">{{item.weixin}}</td>
              <td class="td-marketing">{{item.marketing}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "../api/user";
export default {
  
  props: ["isail","dailyuid"],
  data() {
    return {
      ails: this.isail,
      uid:this.dailyuid,
      jounList: [
        
      ],
    };
  },
  methods: {
    close() {
      this.ails = false;
      // this.ail = !this.ail;
    },
    async getlog()
    {
      let res = await api.oplog(this.uid)
      if(res.code == 1)
      {
        this.jounList = res.data;
      }
    }
  },
  watch: {
    isail(newval) {
      console.log("---", newval);
      this.ails = newval;
      
    },
    dailyuid(newval)
    {
      this.uid = newval;
      this.getlog();
    }
  },
};
</script>

<style lang="less" scoped>
.hand {
  background-color: #f8fafc;
}
.box {
  width: 760px;
  // height: 920px;
  margin-top: 0px;
  float: right;
  opacity: 1;
  position: absolute;
  top: 0px;
  right: 0;
  z-index: 22;
  // background-color: #fff;
}
img {
  float: left;
  width: 103px;
  height: 103px;
  margin-top: 36px;
  // margin-left: -29px;
  position: absolute;
  left: -29px;
}
i {
  font-style: normal;
}
.xin {
  // width: 32px;
  height: 21px;
  font-style: normal;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2c68ff;
  position: absolute;
  top: 44px;
  right: 69px;
  opacity: 1;
  cursor: pointer;
}
.shua {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 0px;
  right: 115px;
  cursor: pointer;
}
h1 {
  line-height: 80px;
  width: 270px;
  height: 26px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #2a3346;
  opacity: 1;
}
.box-main {
  width: 720px;
  height: 920px;
  background: #fff;
  margin-left: 40px;
  box-shadow: 0px 3px 20px rgba(179, 189, 195, 0.4);
  opacity: 1;
  border-radius: 0px;
  position: relative;
  overflow-y: scroll;
  padding-left: 30px;
  box-sizing: border-box;
}
.box-main::-webkit-scrollbar{
  width: 10px;
  height: 10px;
}
.main-nav {
  width: 720px;
  height: 80px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
}
.name,
.limits,
.weixin,
.marketing,
.time,
.operate {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 0px;
  color: #2a3346;
  opacity: 1;
  text-align: left;
}
.name {
  width: 90px;
  height: 19px;
  padding-right: 80px;
}
.limits {
  width: 90px;
  height: 19px;
  padding-right: 117px;
}
.weixin {
  width: 80px;
  height: 19px;
  padding-right: 118px;
  opacity: 1;
}
.marketing {
  width: 30px;
  height: 19px;
}
thead {
  height: 50px;
}
tr {
  width: 720px;
  height: 70px;
}

tr:hover{
  background-color: #F9FAFE !important;
}
.bg_color {
  background-color: #ffffff !important;
}
 
.bg_color2 {
  background-color: #fafafa !important;
}


.td-name,
.td-limits,
.td-weixin,
.td-marketing,
.td-time {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 32px;
  color: #727e96;
  box-sizing: border-box;
  opacity: 1;
}
.td-name {
  width: 138px;
  height: 19px;
}
.td-limits {
  width: 62px;
  height: 19px;
}
.td-weixin {
  width: 78px;
  height: 19px;
  padding-right: 55px;
}
.td-marketing {
  width: 28px;
  height: 19px;
}
.td-time {
  width: 78px;
  height: 38px;
}
.td-xuan {
  width: 245px;
  height: 19px;
  //   background-color: pink;
}
.check,
.mark,
.know,
.limit,
.daily {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 32px;
  color: #2c68ff;
  opacity: 1;
}
.check,
.mark,
.limit,
.daily {
  width: 28px;
  height: 20px;
}
.know {
  width: 42px;
  height: 20px;
}
</style>
