<template>
  <div class="box">
    <div class="nav-box">
      <div class="mt-4">
        <el-input
          v-model="keyword"
          placeholder="请输入搜索内容"
          class="input-with-select"
          @keyup.enter="search"
        >
          <template #append>
            <el-button
              class="iconfont icon-fangdajing"
              @click="search"
            ></el-button>
          </template>
        </el-input>
      </div>

      <!-- 右边筛选 导出 刷新 -->
      <div class="screen" @click="perAdd" v-if="!getlook">
        <img class="screen-img" src="../assets/img/ding/tianjia.png" alt="" />
        <p class="screen-p">添加</p>
      </div>
      <div class="export"  v-if="!getlook">
        <el-checkbox-button
          v-model="checked1"
          :label="recycle"
          size="large"
          @change="state3"
        />
      </div>
    </div>

    <div class="main">
      <staff :isState="isState" :issx="sxx" :skeyword="skeywords"></staff>
    </div>

    <detail
      :istail="isshow"
      :userdetail="arrs"
      :listData="listData"
      :isreadonly="false"
      @detailsxx="sx"
    ></detail>
  </div>
</template>

<script>
import { ref } from "vue";
import staff from "../components/staff.vue";
import Detail from "../components/detail.vue";
import * as api from "../api/user";

const input3 = ref("");

export default {
  components: { staff, Detail },
  data() {
    return {
      input3,
      arrs: {
        username: "",
        name: "",
        tel: "",
      },
      isshow: false,
      a: 0,
      listData: [],
      sxx: 0,
      keyword: "",
      skeywords: "",
      recycle: "回收站",
      checked1: false,
      isState: 2,
    };
  },
  methods: {
    state3(e) {
      if (e) {
        this.isState = 1;
      } else {
        this.isState = 2;
      }
    },
    async perAdd() {
      this.a++;
      this.isshow = this.a;
      if (this.isshow == 3) {
        this.isshow = 1;
        this.a = 1;
      }
      let res = await api.myrolelist();
      this.listData = res.data.rolelist;
      console.log("-----aass", res.data.rolelist);
    },
    search() {
      this.skeywords = this.keyword;
    },
    sx() {
      this.sxx++;
      console.log("刷新", this.sxx);
    },
  },
  computed: {
    getlook() {
      return this.$store.state.lookonly;
    },
  },

  
};
</script>

<style lang="less" scoped>
.mt-4 {
  float: left;
  width: 300px;
  height: 32px;
  // margin-left: 60px;
  margin-top: 40px;
}
.box {
  box-sizing: border-box;
  width: 1730px;
  height: 840px;
  margin: 0;
  margin-left: 193px;
  margin-top: 0px;
  padding: 30px;
  background-color: #f8fafc;
}
.nav-box {
  width: 1670px;
  height: 120px;
  background-color: #fff;
  overflow: hidden;
  padding-left: 30px;
  box-sizing: border-box;
}
.left {
  float: left;
  width: 300px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #dadfe6;
  opacity: 1;
  margin-left: 30px;
  margin-top: 30px;
  margin-right: 850px;
  border-radius: 2px;
  position: relative;
}
span {
  float: left;
  width: 42px;
  height: 19px;
  font-size: 14px;
  margin-top: 20px;
  margin-left: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  margin-right: 16px;
  color: #2a3346;
  opacity: 1;
}
.jian {
  float: left;
  transform: rotate(90deg);
  margin-right: 17px;
  margin-top: 10px;
}
.xian {
  float: left;
  width: 0px;
  height: 14px;
  margin-top: 14px;
  margin-right: 14px;
  border: 1px solid #dadfe6;
  opacity: 1;
}
.screen {
  float: right;
  width: 130px;
  height: 50px;
  margin-top: 30px;
  margin-right: 60px;
  background: #2c68ff;
  opacity: 1;
  cursor: pointer;
  border-radius: 2px;
}
.flush {
  float: left;
  width: 130px;
  height: 50px;
  margin-left: 30px;
  margin-top: 30px;
  border: 1px solid #2c68ff;
  opacity: 1;
  border-radius: 2px;
}
.export {
  float: right;
  width: 130px;
  height: 50px;
  margin-top: 30px;
  margin-right: 30px;
  border: 1px solid #2c68ff;
  opacity: 1;
  cursor: pointer;
  border-radius: 2px;
}
.export /deep/ .el-checkbox-button__inner {
  width: 130px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
}
.export /deep/ .el-checkbox-button.is-checked .el-checkbox-button__inner {
  background-color: #2c68ff;
  box-shadow: none !important;
  border-radius: 0;
}
.screen-img {
  float: left;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  margin-left: 27px;
  margin-top: 14px;
}
.screen-p {
  float: left;
  margin-top: 27px;
  // width: 32px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 0px;
  color: #ffffff;
  opacity: 1;
}
.export-img,
.flush-img {
  float: left;
  width: 26px;
  height: 26px;
  margin-right: 16px;
  margin-left: 27px;
  margin-top: 12px;
}
.export-p,
.flush-p {
  float: left;
  width: 32px;
  height: 22px;
  font-size: 16px;
  margin-top: 25px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 0px;
  color: #2c68ff;
  opacity: 1;
}
input {
  float: left;
  width: 70px;
  height: 19px;
  font-size: 14px;
  margin-top: 10px;
  border: none;
  outline: none;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #9ea3b9;
  opacity: 1;
}
.jing {
  float: left;
  width: 40px;
  height: 40px;
  background: #f7f9fb;
  border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px;
  position: absolute;
  top: -1px;
  right: -1px;
}
.icon {
  width: 14px;
  height: 14px;
  margin-left: 13px;
  margin-top: 13px;
  //   border: 2px solrgb(27, 28, 29)e96;
  border-radius: 50%;
  opacity: 1;
}
.main {
  width: 1670px;
  height: 660px;
  padding-left: 30px;
  box-sizing: border-box;
  background-color: #fff;
}

.mt-4 /deep/ .el-input__inner {
  height: 32px;
}
.mt-4 /deep/ .el-input-group__append {
  height: 32px;
}
</style>
